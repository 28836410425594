<template>
  <div class="partyBuilding">
    <div class="partyBuilding-t">
      <div class="banner">
        <img src="./img/banner.png" alt="图片加载中..." />
      </div>
      <div class="iconList">
        <div class="item te" @click="toPartyMember">
          <div class="item-t"><img src="./img/icon1.png" alt="" /></div>
          <div class="item-b">议事代表</div>
        </div>
        <div class="item" @click="toPartyAffairs">
          <div class="item-t"><img src="./img/icon2.png" alt="" /></div>
          <div class="item-b">议事规则</div>
        </div>
        <div class="item" @click="toRedHousekeeper">
          <div class="item-t"><img src="./img/icon3.png" alt="" /></div>
          <div class="item-b">议事流程</div>
        </div>
      </div>
    </div>
    <!-- <div class="classListBanner">
        <img src="./img/classListBanner.png" @click="toClassScheduleCard()" />
      </div> -->
    <div class="oTitle" @click="toMore(66)">
      <div class="title-l">井亭议事廊</div>
      <div class="title-r">
        <div class="txt">更多</div>
        <div class="img"><img src="./img/newArrow.png" alt="" /></div>
      </div>
    </div>
    <div class="partyBuilding-main">
      <div
        class="item"
        @click="toYishiDetail(item)"
        v-for="(item, index) in listData"
        :key="index"
      >
        <div class="item-l">
          <div class="title">
            {{ item.description }}
          </div>
          <div class="item-lb">
            <div class="item-lbl">{{ item.insertTime }}</div>
            <div class="item-lbr">
              <div class="txt">{{ item.reactionUser }}</div>
            </div>
          </div>
        </div>
        <div class="item-r">
          <img
            :src="item.picture ? item.picture : require('./img/topic.png')"
            alt=""
          />
        </div>
      </div>
    </div>
    <div class="oTitle" @click="toMore(67)">
      <div class="title-l">人大资讯站</div>
      <div class="title-r">
        <div class="txt">更多</div>
        <div class="img"><img src="./img/newArrow.png" alt="" /></div>
      </div>
    </div>
    <div class="partyBuilding-main">
      <div
        class="item"
        v-for="(item, index) in listData1"
        :key="index"
        @click="toDetail(item, index)"
      >
        <div class="item-l">
          <div class="title">
            {{ item.topicTitle }}
          </div>
          <div class="item-lb">
            <div class="item-lbl">{{ item.createTime }}</div>
            <div class="item-lbr">
              <div class="img"><img src="./img/look.png" alt="" /></div>
              <div class="txt">{{ item.readCount }}</div>
            </div>
          </div>
        </div>
        <div class="item-r">
          <img :src="item.topicPicture" alt="" />
        </div>
      </div>
    </div>
    <div class="submit" @click="toYishi">我要议事</div>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import { getTopicListURL, getListForMiniURL } from "./api.js";
import { getHashParam } from "@/utils/utils.js";
import { gloabalCount, toRegister } from "@/utils/common";
var moment = require("moment");
export default {
  name: "partyBuilding",
  data() {
    return {
      fromFlag: "",
      form: {
        donatePic: "",
        donateContent: "",
      },
      pointsData: {
        totlePointCount: 0,
      },
      curPage: 1,
      pageSize: 2,
      listData: [],
      listData1: [],
      bannerList: {},
    };
  },
  components: {},
  destroyed() {},
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    houseId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  created() {
    this.fromFlag = this.$route.query.fromFlag;
    if (this.$route.query.latitude) {
      localStorage.setItem("latitude", this.$route.query.latitude);
    }
    if (this.$route.query.longitude) {
      localStorage.setItem("longitude", this.$route.query.longitude);
    }
  },
  mounted() {
    this.getPartyList();
    this.getPartyList1();
  },
  methods: {
    async toYishi() {
      if (
        await toRegister(this.$route.path, this.$route.query, "热门活动报名")
      ) {
        this.$router.push({
          name: "peopleReactionForm",
        });
      }
    },
    toMore(data) {
      if (data == 67) {
        this.$router.push({
          name: "peopleReactionTopicList",
          query: { type: data },
        });
      } else {
        this.$router.push({
          name: "peopleReactionTopicListElse",
          query: { type: data },
        });
      }
    },
    toBanner() {
      this.$router.push({
        name: "partyNotice",
      });
    },
    toRedHousekeeper() {
      this.$router.push({
        name: "peopleReactionFlow",
      });
    },
    toSpeak() {
      window.location.href = "https://future.zhuneng.cn/QRCodeMqtb/";
    },
    toYishiDetail(item) {
      let params = {
        id: item.id,
      };
      this.$router.push({
        name: "peopleReactionDetail",
        query: {
          id: item.id,
        },
      });
    },
    toDetail(item) {
      let params = {
        id: item.id,
      };
      if (this.fromFlag == "dingding") {
        this.$router.push({
          name: "partyTopic",
          query: {
            id: item.id,
          },
        });
      } else {
        if (wx.miniProgram) {
          wx.miniProgram.navigateTo({
            url: `/xubPages/partyInfoDetail/index?params=${JSON.stringify(
              params
            )}`,
          });
        }
      }
    },
    toPartyAffairs() {
      this.$router.push({
        name: "commentRule",
        query: {
          fromFlag: this.fromFlag,
        },
      });
    },
    toPartyMember() {
      this.$router.push({
        name: "commentRepresentative",
      });
    },
    async getPartyList1() {
      let res = await this.$axios.get(getTopicListURL, {
        params: {
          tenantId: this.tenantId,
          houseId: this.houseId || this.communityId || undefined,
          userId: this.userId,
          status: 1, // 0：待审核；1：审核通过；2：审核不通过；3：已关闭；4：删除
          topicType: "67", // 1. 和伙人风采 2.超话 3. 活动 7：支委会；8：党员大会；9：党小组会；10：党课；11：主题党日
          curPage: 1,
          pageSize: this.pageSize,
          sortField: "NEW",
        },
      });
      if (res.code === 200) {
        res.data.records.forEach((item) => {
          // 如果请求数据不重复，则添加进listData
          this.listData1.push(item);
        });
      }
    },
    async getPartyList() {
      let res = await this.$axios.get(getListForMiniURL, {
        params: {
          tenantId: this.tenantId,
          userId: this.userId,
          curPage: 1,
          pageSize: this.pageSize,
        },
      });
      if (res.code === 200) {
        this.finishedText = "没有更多了";
        if (res.data.records.length === 0) {
          this.listData = [];
          this.finishedText = "";
          return;
        }
        res.data.records.forEach((item) => {
          // 如果请求数据不重复，则添加进listData
          this.listData.push(item);
        });
      } else {
      }
    },
  },
};
</script>

<style lang="less" scoped>
.partyBuilding {
  min-height: 100vh;
}
.partyBuilding {
  .partyBuilding-t {
    .banner {
      height: 292px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .iconList {
      display: flex;
      padding: 28px 20px 38px;
      align-items: center;
      justify-content: space-around;
      .item-t {
        width: 108px;
        height: 108px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .item-b {
        font-size: 28px;
        margin: 6px auto;
        font-weight: 600;
        color: #1a1c34;
        line-height: 40px;
        text-align: center;
      }
      .te {
        .item-t {
          width: 108px;
          height: 108px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
  .classListBanner {
    box-sizing: border-box;
    width: 100%;
    height: 176px;
    padding: 0 30px;
    img {
      width: 690px;
      height: 100%;
    }
  }
  .oTitle {
    padding: 0 34px 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
    .title-l {
      font-weight: 700;
      font-size: 36px;
      color: #323334;
      line-height: 50px;
      position: relative;
      padding-left: 40px;
    }
    .title-r {
      display: flex;
      align-items: center;
      .txt {
        font-weight: 500;
        font-size: 24px;
        color: #fb8133;
        line-height: 28px;
      }
      .img {
        width: 28px;
        height: 28px;
        font-size: 0;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .partyBuilding-main {
    .item {
      padding: 20px 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid rgba(0, 0, 0, 0.06);
      .item-l {
        flex: 1;
        margin-right: 38px;
        .title {
          font-size: 32px;
          font-weight: 400;
          color: rgba(0, 0, 0);
          line-height: 44px;
          margin-bottom: 28px;
        }
        .item-lb {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .item-lbl {
            font-size: 24px;
            font-weight: 400;
            color: #b4b4b4;
            line-height: 34px;
          }
          .item-lbr {
            display: flex;
            align-items: center;
            .img {
              width: 28px;
              height: 26px;
              margin-right: 4px;
              font-size: 0;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .txt {
              font-size: 24px;
              font-weight: 400;
              color: #b4b4b4;
              line-height: 34px;
            }
          }
        }
      }
      .item-r {
        width: 226px;
        height: 150px;
        border-radius: 8px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 8px;
        }
      }
    }
  }
  .submit {
    width: 612px;
    height: 76px;
    background: #fc823a;
    border-radius: 38px;
    position: fixed;
    font-weight: 400;
    font-size: 32px;
    color: #ffffff;
    line-height: 76px;
    text-align: center;
    left: 0;
    right: 0;
    bottom: 60px;
    z-index: 999;
    margin: auto;
  }
}
</style>
