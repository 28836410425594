//提交反馈
const submitPeopleCongressReaction = `/gateway/hc-serve/peopleCongressReaction/submitPeopleCongressReaction`;
//议事廊
const getListForMiniURL = `/gateway/hc-serve/peopleCongressReaction/getListForMini`;
//议事廊详情
const getDetailsMiniURL = `/gateway/hc-serve/peopleCongressReaction/getDetails`;
//根据提交人id获取列表
const getListByUserIdUrl = `/gateway/hc-serve/peopleCongressReaction/getListByUserId`;
//用户信息
const userInfoUrl = `/gateway/hc-mini/user/mini/user-info`;
// 话题列表
const getTopicListURL = `/gateway/hc-neighbour/topic/getTopicList`;
// 查询话题详情
const getTopicInfoByIdURL = `/gateway/hc-neighbour/topic/getTopicInfoById`;
// 点赞
const addPraiseURL = `/gateway/hc-neighbour/topic/addPraise`;
// 取消点赞
const cancelPraiseURL = `/gateway/hc-neighbour/topic/cancelPraise`;
// 进行评论
const addCommentURL = `/gateway/hc-neighbour/topic/addComment`;
// 话题评论列表分页查询
const getTopicCommentList = `/gateway/hc-neighbour/topic/getTopicCommentList`;
// 评论回复列表分页查询
const getTopicReplyList = `/gateway/hc-neighbour/topic/getTopicReplyList`;
export {
  getDetailsMiniURL,
  getListForMiniURL,
  getTopicListURL,
  getTopicInfoByIdURL,
  addPraiseURL,
  cancelPraiseURL,
  addCommentURL,
  getTopicCommentList,
  getTopicReplyList,
  submitPeopleCongressReaction,
  userInfoUrl,
  getListByUserIdUrl,
};
